<template>
  <div id="scroll-h" ref="scrollWrapperRef" @scroll="handleScroll" @touchstart="handleTouch" @touchmove="handleTouch" @touchend="handleTouch">
    <router-view />
  </div>
</template>
<script setup>
import { ref } from "vue";

let prohibitTop = false
let prohibitBot = false
const scrollWrapperRef = ref() //滚动区域的父元素
const handleScroll = () => { //滚动触发的事件
  const scrollWrapper = scrollWrapperRef.value // 获取滚动容器
  const { scrollTop, clientHeight, scrollHeight } = scrollWrapper // 获取滚动数据
  prohibitTop = false
  prohibitBot = false
  if (scrollTop == 0) {
    prohibitTop = true
  }
  // 判断是否滚动到底部
  if (scrollTop + clientHeight >= scrollHeight - 10) {
    // 滚动到底部时触发加载数据的函数
    prohibitBot = true
  }
}

let touchStartY = 0
let touchPendingY = 0
let touchEndY = 0
const handleTouch = (e) => {
  switch(e.type){
    case 'touchstart':
      touchStartY = e.touches[0].pageY
      break;
    case 'touchmove':
      touchPendingY = e.touches[0].pageY
      touchEndY = touchStartY - touchPendingY
      console.log(touchEndY);
      if (prohibitBot || prohibitTop){
        // document.body.style.position = 'fixed'
        // document.body.style.top = '0px'
        // document.body.style.left = '0px'
      }

      if (prohibitBot && touchEndY < 0) {
        // document.body.style.position = ''
      }

      if (prohibitTop && touchEndY > 0) {
        // document.body.style.position = ''
      }
      break;
  }
}

</script>

<style>
#scroll-h{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE10+ */
}
#scroll-h::-webkit-scrollbar {
  display: none; /* ChromeSafari */
}
</style>
