import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  // 首页
  {
    path: "/",
    name: "home",
    component: () => import("@/layout/index.vue"),
    redirect: '/',
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/index.vue"),
      },
      // 公益合作
      {
        path: "/welfare",
        name: "welfare",
        component: () => import("@/views/welfare"),
      },
      // 项目
      {
        path: "/project",
        name: "project",
        component: () => import("@/views/project"),
      },
      // 档案
      {
        path: "/dossier",
        name: "dossier",
        component: () => import("@/views/dossier"),
      },
      // 公益合作---子项
      {
        path: "/institutions",
        name: "institutions",
        component: () => import("@/views/welfare/institutions/index.vue"),
      },
      // 项目---子项
      {
        path: "/projectInfo",
        name: "projectInfo",
        component: () => import("@/views/project/info/index.vue"),
      },
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  next()
});
export default router;
